import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContactForm from '../components/contact-form';
import { CONSTANTS } from '../utils/constants';

import brains from '../images/anatomy-brains.webp';

import '../scss/pages/anatomy.scss';

const Anatomy = () => {
  const metaTags = {
    name: 'Anatomy Contact Us',
    description: 'Deerfield Anatomy Contact Us',
    keywords: 'Deerfield, Contact Us',
  };

  return (
    <Layout meta={metaTags}>
      <div className='anatomy-hero-container'>
        <ContentBlock
          outerBgColor={CONSTANTS.TAN}
          className='anatomy-header block'
        >
          <Grid>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10} className='anatomy-content title-block'>
                <p className='dissect-text'>Dissecting the</p>
                <h1 className='title-margin'>
                  <span className='large-text'>ANATOMY</span>
                  <span className='small-text' style={{ paddingLeft: '3px', paddingRight: '3px' }}> OF </span>
                  <span className='large-text'>MARKETING</span>
                </h1>
              </Col>
              <Col xs={1}></Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ContentBlock outerBgColor={CONSTANTS.TAN} className='block'>
          <Grid>
            <Row>
              <Col lg={1}></Col>
              <Col xs={12} md={6} lg={5} className='anatomy-content'>
                <ContactForm />
              </Col>
              <Col xs={12} md={6} lg={6} className='image-content container-margin'>
                <img
                  src={brains}
                  alt='an image of a brain and a heart connected by a nerve'
                  className='brain-img'
                  draggable="false"
                />
                <div className='figure-and-columns'>
                  <p className='txt-figure'>Figure:</p>
                  <Row className='table-row'>
                    <Col xs={1} md={1} className='col-style txt-letters letter-column'>a.</Col>
                    <Col xs={11} md={11} className='col-style text-column'>A brain for communication strategies that think outside the box</Col>
                  </Row>
                  <Row className='table-row'>
                    <Col xs={1} md={1} className='col-style txt-letters letter-column'>b.</Col>
                    <Col xs={11} md={11} className='col-style text-column'>The nerve to engage in unbiased collaboration throughout a brand’s life cycle, to push the limits of science and innovation </Col>
                  </Row>
                  <Row className='table-row'>
                    <Col xs={1} md={1} className='col-style txt-letters letter-column'>c.</Col>
                    <Col xs={11} md={11} className='col-style text-column'>A heart that beats for marketing that breaks free from the status quo</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    </Layout>
  );
};

export default Anatomy;
